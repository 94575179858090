<template>
    <div class="page keys-timer-page">
        <div class="page__title">
            <nav-back/>
            Таймеры ключей
            <ui-button color="blue" class="page__title-button" @clicked="onSaveClick">
                Сохранить
            </ui-button>
        </div>

        <div class="keys-timer-page__settings">
            <label class="mt-y text-left">Начисляемая валюта</label>
            <b-form-select v-model.number="moneyCurrencyId" :options="currenciesOptions" class="form-control"/>

            <label class="my-0 text-left">Сколько начисляем (шт.)</label>
            <input type="number" class="form-control" v-model.number="moneyCount"
                   min=0 oninput="validity.valid||(value=0)"
            />

            <label class="my-0 text-left">Длительность (hh:mm)</label>
            <vue-timepicker class="keys-timer-page__picker" format="HH:mm" v-model="stringTimerDuration"
                            @input="onDurationChanged"/>
        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters,} from 'vuex';
import bus from '@/plugins/bus';
import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue';

export default {
    data: () => ({
        moneyCurrencyId: 0,
        moneyCount: 0,
        timerDuration: 0,
        currenciesOptions: [{value: 0, text: 'Ожидание'}],
        stringTimerDuration: '00:00:00',
        timerInitialized: false,
        firstTimerChanged: false
    }),
    components: {
        VueTimepicker,
        UiButton: () => import('../../components/ui/UiButton'),
        NavBack: () => import('../../components/navigation/NavBack'),
    },
    computed: {
        ...mapGetters('firebase', ['economyConfig', 'currencies'])
    },
    async created() {
        await this.getConfig();
        bus.$off(this.FIREBASE_CONFIG_CHANGED_EVENT, this.setData).$on(this.FIREBASE_CONFIG_CHANGED_EVENT, this.setData);
        this.setData();
    },
    beforeDestroy() {
        bus.$off(this.FIREBASE_CONFIG_CHANGED_EVENT, this.setData);
    },
    methods: {
        ...mapActions('firebase', ['getConfig', 'updateEconomyConfig']),
        async onSaveClick() {
            const data = {
                currency_timer_currency_id: this.moneyCurrencyId,
                currency_timer_amount: this.moneyCount,
                currency_timer_duration: this.timerDuration
            };
            const response = await this.updateEconomyConfig(data);

            if (!response.error)
                bus.$emit('show-notification-message', 'Сохранено');
            else
                bus.$emit('show-notification-message', `Ошибка: ${response.error.toString()}`);
        },
        setData() {
            this.currenciesOptions = this.currencies.map(currency => {
                return {value: currency.id, text: currency.name};
            });
            this.moneyCurrencyId = this.economyConfig.currency_timer_currency_id || 1;
            this.moneyCount = this.economyConfig.currency_timer_amount || 0;
            this.timerDuration = this.economyConfig.currency_timer_duration || 0;
            this.stringTimerDuration = new Date(this.timerDuration * 1000).toISOString().substr(11, 8);
            this.timerInitialized = true;
        },
        onDurationChanged(stringTimerDuration) {
            if (!this.timerInitialized) return;
            if (!this.firstTimerChanged) {
                this.firstTimerChanged = true;
                return;
            }
            const [hours, minutes] = stringTimerDuration.split(':');
            this.timerDuration = (+hours) * 60 * 60 + (+minutes) * 60;
            isNaN(this.timerDuration) && (this.timerDuration = 0);
        }
    }
};
</script>

<style lang="scss">
.keys-timer-page {
    &__settings {
        margin-top: 12px;
        display: grid;
        grid-template-columns: max-content max-content;
        grid-row-gap: 12px;
        grid-column-gap: 8px;
        align-items: center;
    }

    &__picker {
        width: 100%;
        font-family: 'Open Sans', sans-serif;

        .display-time {
            width: 100% !important;
            color: #495057 !important;
            border: 1px solid #ced4da !important;
            border-radius: 0.25rem !important;
            font-size: 1rem !important;
            height: calc(1.5em + 0.75rem + 2px) !important;

            &:focus {
                border-color: #80bdff;
                outline: 0;
                box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
            }
        }

        .controls {
            top: 3px;
            right: 5px;
        }
    }
}
</style>
